<template>
  <div class="container">
    <el-row class="row">
      <el-col :span="3" style="text-align: left">
        <el-button class="exit user-info-buttom" @click="registerUser"> Register user</el-button>
      </el-col>
      <!-- <el-col :span="3" style="text-align: left; height: 40px; line-height: 40px">
        <a href="http://flowwatch.cgtf.net/supervisor/" target="_blank"
          >Go to Supervisor</a
        >
      </el-col> -->
    </el-row>
    <div class="table-box">
      <PaginationTable :loading="loading" :originData="userList" :showRule="showRule"></PaginationTable>
    </div>
    <Dialog
      title="Register"
      :visible.sync="RegisterVisible"
      :sub-component="Registration"
      width="600px"
      :showFooter="false"
      @close_diolog="RegisterVisible = false"
    >
    </Dialog>
  </div>
</template>

<script>
import Dialog from '@/components/Dialog'
import Registration from '@/components/Registration'
import { getAllUsers } from '@/api/UserApi.js'
import { formatData } from '@/utils/dateHandler.js'
// import PaginationTable from '@/components/PaginationTable'
export default {
  name: 'UserList',
  components: {
    Dialog,
    // PaginationTable,
  },
  created() {},
  data() {
    return {
      RegisterVisible: false,
      Registration,
      userList: [],
      loading: false,
      showRule: [
        {
          prop: 'user_name',
          label: 'User name',
        },
        {
          prop: 'email',
          label: 'Email',
        },
        {
          prop: 'create_date',
          label: 'Create date',
        },
      ],
    }
  },
  mounted() {
    this.getAllUsers()
  },
  props: {},
  methods: {
    async getAllUsers() {
      this.loading = true
      const userList = await getAllUsers()
      this.userList = userList.map(user => {
        const date = new Date(user.create_date - new Date().getTimezoneOffset() * 60000)
        user.create_date = formatData(date)
        user.email = user.email || 'Empty'
        return user
      })
      this.loading = false
    },
    registerUser() {
      this.RegisterVisible = true
    },
  },
}
</script>

<style lang="scss" scoped></style>
