<template>
  <el-form
    ref="RegisterForm"
    :rules="rules"
    :model="RegisterForm"
    status-icon
    class="login-form"
    label-width="150px"
  >
    <el-form-item label="UserName:" prop="userName" style="font-weight: bold">
      <el-input
        v-model.number="RegisterForm.userName"
        placeholder="Please input username"
        class="login-input"
      ></el-input>
    </el-form-item>
    <el-form-item label="Password:" prop="password" style="font-weight: bold">
      <el-input
        type="password"
        v-model="RegisterForm.password"
        placeholder="Please input password"
        show-password
        class="login-input"
      ></el-input>
    </el-form-item>
    <el-form-item
      label="Repeat password:"
      prop="password_repeat"
      style="font-weight: bold"
    >
      <el-input
        type="password_repeat"
        v-model="RegisterForm.password_repeat"
        placeholder="Please input password again"
        show-password
        class="login-input"
      ></el-input>
    </el-form-item>
    <el-button :loading="loading" class="login-submit" @click="register"
      >Sign up</el-button
    >
  </el-form>
</template>

<script>
import { register } from '@/api/UserApi'
import { Message } from 'element-ui'
export default {
  name: 'Registration',
  created() {},
  data() {
    return {
      loading: false,
      rules: {
        userName: [
          { required: true, message: 'Please input user name', trigger: 'blur' },
        ],
        password: [{ required: true, message: 'Please input password', trigger: 'blur' }],
        password_repeat: [
          { required: true, message: 'Please input password again', trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              if (value !== this.RegisterForm.password) {
                callback(new Error('The two passwords are inconsistent!'))
              } else {
                callback()
              }
            },
            trigger: 'blur',
          },
        ],
      },
      RegisterForm: {
        userName: '',
        password: '',
        password_repeat: '',
      },
    }
  },
  props: {
    model: {
      type: Object,
      default: () => {},
    },
    param: {
      type: Object,
      default: () => {},
    },
  },
  inject: ['reload'],
  methods: {
    async register() {
      try {
        await this.$refs.RegisterForm.validate()
        this.loading = true
        const { status, data } = await register(
          this.RegisterForm.userName,
          this.RegisterForm.password
        )
        this.loading = false
        if (data && status !== 'failed') {
          Message({
            message: 'Register Success',
            type: 'success',
            duration: 5 * 1000,
          })
          this.reload()
        }
      } catch (e) {}
    },
  },
}
</script>

<style lang="scss" scoped>
.login-input {
  width: 300px;
}

.login-form {
  width: 450px;
  text-align: center;
}

.login-submit {
  width: 450px;
  background-color: #4b127d;
  border-color: #4b127d;
  color: #fff;
  margin-bottom: 50px;
}
</style>
